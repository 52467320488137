html, body {
  display: flex;
  justify-content: center;
  margin: auto;
  color: rgb(0, 255, 0);
  background-color: rgb(0, 255, 0);
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  -webkit-font-smoothing: antialiased;
  overscroll-behavior: none;
  touch-action: none;
}
